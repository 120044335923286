import { FC, ReactNode } from 'react';
import dynamic from 'next/dynamic';
import { As, Box, Grid, GridProps, Text, ChakraProps } from '@chakra-ui/react';
import { TeaserHeading, Teaser as TeaserProps } from '@/types';
import Heading from './Heading';

const ButtonLink = dynamic(() => import('./ButtonLink'));
const HtmlContent = dynamic(() => import('./HtmlContent'));
const Image = dynamic(() => import('./Image'));

export interface Props extends Omit<Partial<TeaserProps>, 'id' | 'title'> {
  heading: TeaserHeading;
  textStyle?: string;
  as?: As;
  innerProps?: GridProps;
  meta?: ReactNode;
  alignInnerItems?: ChakraProps['alignItems'];
}

const Teaser: FC<Props & ChakraProps> = ({
  date,
  lead,
  wysiwyg,
  heading: { title, ...headingProps },
  image,
  textStyle,
  children = null,
  alignInnerItems = 'flex-start',
  innerProps = null,
  link,
  meta,
  ...rest
}) => {
  return (
    <Box {...rest}>
      {image?.src && (
        <Image
          width="435"
          height="195"
          src={image.src}
          alt={image?.alt || (typeof title === 'string' ? title : '')}
          borderBottomRadius="0"
        />
      )}
      <Grid
        alignItems={alignInnerItems}
        justifyItems={alignInnerItems}
        py={3}
        flex="1 1 auto"
        display="flex"
        flexDirection="column"
        {...innerProps}
      >
        {meta && meta}
        <Heading mt={0} mb="4" {...headingProps}>
          {title}
        </Heading>
        {date && (
          <Text as="time" mb="2">
            {date}
          </Text>
        )}
        {lead && (
          <Text
            mb="4"
            textStyle={textStyle || 'sm'}
            noOfLines={4}
            maxWidth="65ch"
          >
            {lead}
          </Text>
        )}
        {
          wysiwyg && (
            <HtmlContent html={wysiwyg} />
          ) /* Wysiwyg editor outputs string without wrapping p */
        }
        {children}
        {link && (
          <ButtonLink
            link={{ ...link, mt: 'auto', pt: '4' }}
            colorScheme="primary"
            variant="solid"
            mb="0"
          />
        )}
      </Grid>
    </Box>
  );
};

export const TeaserCard: typeof Teaser = ({
  innerProps = null,
  as: teaserAs,
  ...props
}) => (
  <Teaser
    boxShadow="md"
    transition="box-shadow 0.15s"
    borderRadius="lg"
    backgroundColor="white"
    position="relative"
    innerProps={{ ...innerProps, py: 4, px: 5, borderBottomRadius: 'lg' }}
    // If teaser is presented in a list of other cards, it's probably a row of cards inside ArticleGridWrapper, which has overflow: none, which causes shadow to crop
    m={teaserAs === 'li' ? '0 2px 6px' : undefined}
    flex="1 1 auto"
    as={teaserAs}
    height={[null, null, '100%']}
    {...props}
  />
);

export default Teaser;
