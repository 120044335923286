import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { BoxProps, Grid, useBreakpointValue } from '@chakra-ui/react';
import { ListingBlock as Props } from '@/types';
import { RenderWrapper as ListingWrapper } from '../TeaserList';

const ArticleGridWrapper = dynamic(() => import('../ArticleGridWrapper'));
const Carousel = dynamic(() => import('../Carousel'));
const ListingCard = dynamic(() => import('@/components/frontend/ListingCard'));

const ListingBlock = ({
  listings,
  title,
  type,
  showAsGrid,
  ...rest
}: Props & {
  showAsGrid?: boolean;
} & BoxProps) => {
  const { t } = useTranslation('frontoffice');
  const isDesktop = !!useBreakpointValue({ md: true });

  if (!listings.length) {
    return null;
  }

  const heading: string =
    title || (type ? t(`${type}_listings`, 'Viimeisimmät') : '');

  if (showAsGrid) {
    return (
      <ListingWrapper heading={heading} spacing="0" gridColumn="2" {...rest}>
        <Grid layerStyle="grid" as="ul" listStyleType="none">
          {listings?.map((listing) => (
            <li key={listing.public_id}>
              <ListingCard listing={listing} />
            </li>
          ))}
        </Grid>
      </ListingWrapper>
    );
  }
  return (
    <ListingWrapper heading={heading} {...rest}>
      {listings.length < 4 || !isDesktop ? (
        <ArticleGridWrapper alignItems="flex-start" colCount="4">
          {listings?.map((listing) => (
            <li key={listing.public_id}>
              <ListingCard listing={listing} />
            </li>
          ))}
        </ArticleGridWrapper>
      ) : (
        <Carousel>
          {listings?.map((listing) => (
            <ListingCard listing={listing} key={listing.id} width="100%" />
          ))}
        </Carousel>
      )}
    </ListingWrapper>
  );
};

export default ListingBlock;
