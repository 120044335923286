import { HeadingProps, Heading as ChakraHeading } from '@chakra-ui/react';
import { getTextChildren } from '@/lib/utils';

const headingElements = ['h1', 'h2', 'h3', 'h4', 'h5'];

const getVariantByClassname = (className?: string) => {
  if (!className || !className.includes('is-style-')) {
    return 'h2';
  }
  const wpVariantClassName = className
    .split(' ')
    .find((style) => headingElements.includes(style.replace('is-style-', '')));

  return wpVariantClassName?.replace('is-style-', '') || 'h2';
};

const sizeMap: { [key: string]: HeadingProps['size'] } = {
  h1: '3xl',
  h2: '2xl',
  h3: 'xl',
  h4: 'lg',
  h5: 'md',
  h6: 'sm',
};

// Resolve variant to size, because the used sizes are illogical in a sense and resolving size to size would be confusing. At least variant feels controlled and logical
const resolveToSize = (variant: string | null): HeadingProps['size'] =>
  sizeMap[variant || 'h4'];

const Heading = ({
  className,
  variant,
  as: headingAs,
  size,
  ...rest
}: HeadingProps) => {
  /**
   * If heading style is defined in Gutenberg Heading block
   * we extract it from className and use it as variant prop.
   *
   * But only before we check if variant is set by hand
   * Or there's 'as' included which is a heading type of 'as' and then use it
   */

  const resolvedVariant = size
    ? null
    : variant ||
      (headingAs && headingElements.includes(headingAs as string)
        ? (headingAs as string)
        : getVariantByClassname(className));

  if (resolvedVariant && variant && !sizeMap[variant]) {
    // If the component isn't being used in size-controlled -manner, it's an actual custom variant, so use it.
    return (
      <ChakraHeading
        as={headingAs}
        variant={variant}
        {...getTextChildren(rest)}
      />
    );
  }
  return (
    <ChakraHeading
      size={size || resolveToSize(resolvedVariant)}
      as={headingAs}
      {...getTextChildren(rest)}
    />
  );
};

export default Heading;
