import { FC } from 'react';
import { As, Box, List, Grid, GridProps } from '@chakra-ui/react';
import OrderedList from './OrderedList';
import { safariOnlyCss } from '@/theme';

interface Props extends GridProps {
  gridSize?: string;
  isOrderedContent?: boolean;
  colCount?: 3 | 4 | '3' | '4';
  itemMinWidth?: string;
  as?: As;
}

const simpleGridStyles: GridProps = {
  display: ['flex', null, 'grid'],
  gridAutoFlow: 'column',
  gridGap: 5,
  px: [0],
  _before: {
    content: ['""', null, 'none'],
    minWidth: ['calc(var(--chakra-space-5))', null, 0],
  },
  _after: {
    content: ['""', null, 'none'],
    minWidth: ['calc(var(--chakra-space-5))', null, 0],
  },
  margin: ['0 calc(-1 * var(--chakra-space-5))', null, 0],
  sx: {
    scrollSnapType: 'x proximity',
    scrollBarWidth: 'none',
    '> li': {
      minWidth: [
        'calc(70% - var(--chakra-space-5) * 2.5)',
        'calc(60% - var(--chakra-space-5) * 2.5)',
        0,
      ],
      ...safariOnlyCss({ mr: 5 }, 'lg', true),
    },
  },
};

const zero = 0; // SimpleGrid with very simple typings
const getSimpleGridStyles = (colCount = 3, minWidth = '250px'): GridProps => ({
  ...simpleGridStyles,
  gridTemplateColumns: [
    0 as typeof zero,
    null,
    `repeat(${colCount}, ${minWidth ? `minmax(${minWidth}, 1fr)` : '1fr'})`,
  ],
  gridAutoRows: '1fr',
});

/*
 Lists things, so it's a list. Give it list items.
*/
const ArticleGridWrapper: FC<Props> = ({
  children,
  gridSize = 'lgGrid',
  colCount,
  itemMinWidth,
  isOrderedContent = false,
  ...rest
}) => (
  <Box {...rest} overflowX="hidden" paddingBottom="1">
    <Grid
      layerStyle={[gridSize]}
      overflowX="auto"
      paddingBottom="1"
      as={isOrderedContent ? OrderedList : List}
      {...getSimpleGridStyles(
        colCount && Number.parseInt(`${colCount}`, 10),
        itemMinWidth,
      )}
    >
      {children}
    </Grid>
  </Box>
);
export default ArticleGridWrapper;
