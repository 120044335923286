import {
  ChakraProps,
  Flex,
  Grid,
  GridProps,
  Box,
  List,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { FC, ReactNode } from 'react';
import {
  SelectedEntriesField,
  Teaser as TeaserProps,
  TeaserHeading,
} from '@/types';
import ArticleGridWrapper from './ArticleGridWrapper';
import { TeaserCard } from './Teaser';

const Carousel = dynamic(() => import('./Carousel'));
const Heading = dynamic(() => import('./Heading'));
const TeaserSplit = dynamic(() => import('./TeaserSplit'));

/*
 * By default RenderWrapper is made for TeaserList. And because of the needs of TeaserList, it needs to be rendered full-width in mobile and with spacing on desktop.
 * However the rendering this component provides, fits many other cases too and hence would be good to use in more places for consistency in styling. To use it
 * in other places, simply provide props
 *
 * spacing="0" gridColumn="2"
 *
 * for it. Assuming it's used inside FieldsLayout or ArticleLayout where layout is determined by a grid.
 * For complete example see ListingSection -field component.
 *
 * Further reading:
 * The reason why this component sets the "gridColumn" on the top level instead of just for ArticleGridWrapper where it's really needed, is because
 * the above layout component sets the gridColumn for its direct children as it can only affect those. And the ArticleGridWrapper cant have a separate container on the
 * top level of this component because then the header inside RenderWrapper would inherit the the gridRowGap from the layout.
 */
export const RenderWrapper: FC<
  Pick<SelectedEntriesField, 'heading' | 'lead'> &
    ChakraProps & {
      isHeroStyle?: boolean;
      headerContent?: ReactNode;
      spacing?: ChakraProps['px'];
    }
> = ({
  heading,
  children,
  lead,
  isHeroStyle = false,
  headerContent,
  spacing = [5, 5, 0],
  ...chakraProps
}) => (
  <Box
    sx={
      isHeroStyle || chakraProps.gridColumn
        ? undefined
        : { '&': { gridColumn: ['1 / -1', null, '2'] } }
    }
    as="section"
    {...chakraProps}
  >
    <Flex
      gridColumnGap="4"
      flexWrap="wrap"
      justifyContent="space-between"
      px={spacing}
    >
      <Box as="header" flex="1 1 fit-content" maxWidth="80ch">
        {heading && (
          <Heading variant="h5" as="h2" mb={lead ? 2 : [4, 6]}>
            {heading}
          </Heading>
        )}
        {lead && (
          <Text mb={[4, 6]} fontSize="lg" maxWidth="60ch">
            {lead}
          </Text>
        )}
      </Box>
      {headerContent}
    </Flex>
    {children}
  </Box>
);

interface CustomTeaserProps {
  heading?: TeaserHeading;
  children?: ReactNode;
}

const defaultHeading = { as: 'h3', variant: 'h4' };

interface ColCount {
  colCount: string | number;
}
interface ListingProps extends GridProps {
  entries: (Partial<TeaserProps> & CustomTeaserProps)[];
}
const SmallTeaserListing = ({
  entries,
  colCount,
  ...rest
}: ListingProps & ColCount) => {
  const isDesktop = !!useBreakpointValue({ md: true });

  return entries.length < 4 || !isDesktop ? (
    <ArticleGridWrapper {...(rest || null)}>
      {entries?.map(({ id, title, heading, ...entry }) =>
        !title && !heading ? null : (
          <TeaserCard
            key={id}
            {...entry}
            heading={
              (title
                ? { ...defaultHeading, title }
                : { ...defaultHeading, ...heading }) as TeaserHeading
            }
            as="li"
            display="flex"
            flexDirection="column"
          />
        ),
      )}
    </ArticleGridWrapper>
  ) : (
    <Carousel slideCount={colCount}>
      {entries?.map(({ id, title, heading, ...entry }) =>
        !title && !heading ? null : (
          <TeaserCard
            key={id}
            {...entry}
            heading={
              (title
                ? { ...defaultHeading, title }
                : { ...defaultHeading, ...heading }) as TeaserHeading
            }
          />
        ),
      )}
    </Carousel>
  );
};

const HeroTeaserListing = ({ entries, ...props }: ListingProps) => (
  <Grid gridTemplateColumns="1fr" gridRowGap={[4, 6, 8]} as={List} {...props}>
    {entries.map(({ title, lead, id, heading, link, image }) =>
      !title && !heading ? null : (
        <TeaserSplit
          heading={
            (title
              ? { title, as: 'h3' }
              : { as: 'h3', ...heading }) as TeaserHeading
          }
          {...{ lead, link, image }}
          as="li"
          key={id}
          hasContainer
        />
      ),
    )}
  </Grid>
);

const TeaserList = ({
  entries,
  hero_style = false,
  gridProps = null,
  colCount = 4,
  ...rest
}: Omit<SelectedEntriesField, 'entries'> & {
  entries?: (Partial<TeaserProps> & CustomTeaserProps)[];
  gridProps?: null | GridProps;
  headerContent?: ReactNode;
  colCount?: ColCount['colCount'];
} & ChakraProps) => {
  if (!entries || entries?.length < 1) {
    return null;
  }

  return (
    <RenderWrapper isHeroStyle={hero_style} {...rest}>
      {hero_style ? (
        <HeroTeaserListing entries={entries} {...gridProps} />
      ) : (
        <SmallTeaserListing
          entries={entries}
          {...gridProps}
          colCount={colCount}
        />
      )}
    </RenderWrapper>
  );
};

export default TeaserList;
